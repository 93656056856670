import { Box, type SxProps } from '@mui/material'
import { colors } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'

type RowContainerProps = {
  children?: ReactNode
  className?: string
  sx?: SxProps
}

const RowContainer: React.FunctionComponent<RowContainerProps> = memo((props) => {
  const { children, className, sx } = props

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        '& > *:not(:last-child)': {
          borderBottom: `1px solid ${colors.gray[100]}`,
        },
        ...sx,
      }}
      className={className}
    >
      {children}
    </Box>
  )
})
RowContainer.displayName = 'RowContainer'

export default RowContainer
