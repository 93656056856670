import { Box } from '@mui/material'
import { colors } from '@orus.eu/pharaoh'
import { memo } from 'react'
import type { RowProps } from './row'
import { RowBody } from './row-body'
import { commonRowStyles } from './row-styles'

type RowButtonProps = RowProps & {
  onClick?: () => void
  disabled?: boolean
  ariaLabel?: string
}

/**
 * Same as the Row layout with a clickable button at the root.
 */
export const RowButton: React.FunctionComponent<RowButtonProps> = memo((props) => {
  const { sx, onClick, disabled, ariaLabel, ...passedProps } = props

  return (
    <Box
      component="button"
      sx={{
        ...commonRowStyles,
        ...rowButtonStyles,
        ...sx,
      }}
      onClick={onClick}
      disabled={disabled}
      arial-label={ariaLabel}
    >
      <RowBody {...passedProps} />
    </Box>
  )
})
RowButton.displayName = 'RowButton'

const rowButtonStyles = {
  transition: 'filter 0.3s, opacity 0.3s, backgroundColor 0.3s',
  cursor: 'pointer',
  textAlign: 'initial',
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.8,
    backgroundColor: colors.blue[100],
  },
  '&:focus': {
    opacity: 'initial',
  },
  '&:disabled': {
    cursor: 'inherit',
    opacity: 0.5,
    filter: 'grayscale(1)',
  },
} as const
