import { Box, Typography, type SxProps } from '@mui/material'
import { colors } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import { sizesPerRowSize, type RowSize } from './row-styles'

export type RowBodyProps = {
  size: RowSize
  title: string
  titleColor?: string
  subtitle?: string
  subtitleColor?: string
  leftAvatar?: ReactNode
  rightAvatar?: ReactNode
  right?: ReactNode
  sx?: SxProps
}

/**
 * This component is an implementation detail of the Row and RowButton components. You should not use it directly
 */
export const RowBody: React.FunctionComponent<RowBodyProps> = memo((props) => {
  const { size, title, titleColor, subtitle, subtitleColor, leftAvatar, rightAvatar, right } = props

  const { titleVariant, subtitleVariant, leftIconSize, rightIconSize, iconMargin, verticalPadding, minHeight } =
    sizesPerRowSize[size]

  return (
    <>
      {leftAvatar ? (
        <Box
          sx={{
            width: leftIconSize,
            height: leftIconSize,
            display: 'flex',
            marginRight: iconMargin,
            flexShrink: 0,
          }}
        >
          {leftAvatar}
        </Box>
      ) : (
        <></>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: verticalPadding,
          paddingBottom: verticalPadding,
          minHeight,
          justifyContent: 'center',
        }}
      >
        <Typography variant={titleVariant} color={titleColor}>
          {title}
        </Typography>
        {subtitle && subtitleVariant ? (
          <Typography variant={subtitleVariant} sx={{ color: colors.gray[700] }}>
            <Box component="span" sx={{ color: subtitleColor }}>
              {subtitle}
            </Box>
          </Typography>
        ) : (
          <></>
        )}
      </Box>

      <Box sx={{ flex: 1 }} />

      {right ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: iconMargin,
            flexShrink: 0,
          }}
        >
          {right}
        </Box>
      ) : (
        <></>
      )}

      {rightAvatar ? (
        <Box
          sx={{
            width: rightIconSize,
            height: rightIconSize,
            display: 'flex',
            marginLeft: iconMargin,
            flexShrink: 0,
          }}
        >
          {rightAvatar}
        </Box>
      ) : (
        <></>
      )}
    </>
  )
})
RowBody.displayName = 'RowBody'
