import type { Typography } from '@mui/material'
import type { ComponentProps } from 'react'

export type RowSize = 'xs' | 's' | 'm' | 'l' | 'xl'
export const sizesPerRowSize: {
  [rowSize in RowSize]: {
    titleVariant: Exclude<ComponentProps<typeof Typography>['variant'], undefined>
    subtitleVariant?: ComponentProps<typeof Typography>['variant']
    leftIconSize: string
    rightIconSize: string
    iconMargin: string
    verticalPadding: string
    minHeight: string
  }
} = {
  xs: {
    titleVariant: 'body2',
    leftIconSize: '16px',
    rightIconSize: '16px',
    iconMargin: '8px',
    verticalPadding: '8px',
    minHeight: '36px',
  },
  s: {
    titleVariant: 'body1',
    leftIconSize: '24px',
    rightIconSize: '16px',
    iconMargin: '8px',
    verticalPadding: '12px',
    minHeight: '48px',
  },
  m: {
    titleVariant: 'body1',
    leftIconSize: '32px',
    rightIconSize: '16px',
    iconMargin: '16px',
    verticalPadding: '20px',
    minHeight: '64px',
  },
  l: {
    titleVariant: 'body2Medium',
    subtitleVariant: 'body2',
    leftIconSize: '32px',
    rightIconSize: '16px',
    iconMargin: '16px',
    verticalPadding: '16px',
    minHeight: '72px',
  },
  xl: {
    titleVariant: 'body1Medium',
    subtitleVariant: 'body2Medium',
    leftIconSize: '48px',
    rightIconSize: '16px',
    iconMargin: '16px',
    verticalPadding: '18px',
    minHeight: '80px',
  },
}

export const commonRowStyles = {
  display: 'flex',
  alignItems: 'center',
  outline: 'none',
  backgroundColor: 'transparent',
  border: 'none',
}
